

.clients {
  text-align: center;
  padding: 50px 0;
}

.clients h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.clients .line {
  display: block;
  width: 50px;
  height: 3px;
  background: var(--primary-color);
  margin: 0 auto 30px;
}

.clients .carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.clients .carousel-track {
  display: flex;
  transition: transform 0.5s ease;
}

.clients .card {
  min-width: 200px;
  margin: 0 50px;
}

.clients .card img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


@media screen and (max-width:940px) {

  .clients .card {
    min-width: 200px;
    margin: 0 20px;
  }
  

}









