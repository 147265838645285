/* .overlay{
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}

.graph {
    overflow: hidden;
    background-color: red;
}


.styled-paragraph {
    overflow: hidden;
}

.proContainer {
    max-width: 800px;
    width: 100%;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    overflow: auto;
    background-color: #f2f2f2;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
}

.proContainer p {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 20px;
    color: #000000;
}

.closeBtn {
    text-align: center;
    font-size: 15px;
    font-weight: 100;
    background-color: #cbcbcb;
    position: fixed;
    top: 0;
    right: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border: 0px;
    padding: 7px 20px 7px 20px;
    
}

.proContainer button:hover {
    background-color: red;
}


.styled-table {
    max-width: 1200px;
    object-fit: cover;
    border-collapse: collapse;
    margin-bottom: 20px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.styled-table th, .styled-table td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 12px;
    white-space: pre-line;
    overflow: hidden;
    word-wrap: break-word;
    max-width: 1200px;
}

.styled-table th {
    background-color: #418cd2;
    color: white;
    text-align: center;
}

.styled-table td {
    font-size: 15px;
}

.styled-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.styled-table tbody tr:hover {
    background-color: #ddd;
    transition: background-color 0.3s ease;
}


@media screen and (max-width: 940px) {
    .overlay .proContainer {
        width: 100%;
    }

    .proContainer {
        max-width: 500px;
        position: fixed;
        width: 100%;
        margin: 0 auto;
        top: 40%;
        left: 50%;
    }

    .closeBtn {
        position: fixed;
        right: 0;
        top: 0;
        padding: 7px 20px 7px 20px;
        border: none;
        cursor: pointer;
    }

    .specs li {
        font-size: 16px;
        background-color: red;
        padding: 0;
    }
    .specs href {
        font-size: 8px;
        background-color: #418cd2;
    }
    

    .styled-table th, .styled-table td {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 12px;
        white-space: pre-line;
        overflow: hidden;
        word-wrap: break-word;
        max-width: 150px;
    }

} */

body {
    font-family: 'Arial', sans-serif;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.proContainer {
    background: #fff;
    border-radius: 12px;
    padding: 30px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    position: relative;
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 1;
    transform: scale(1);
}

.overlay .proContainer {
    animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
}

.closeBtn:hover {
    background-color: #d32f2f;
}

.proContainer p {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.specs ul {
    list-style-type: none;
    padding: 0;
}

.specs li {
    margin: 10px 0;
    padding: 10px;
    background-color: #f9f9f9;
    border-left: 5px solid #007BFF;
    transition: background-color 0.3s ease;
    color: #007BFF;
}

.specs div {
    color: black;
}

.specs li:hover {
    background-color: #f1f1f1;
}

.specs a {
    text-decoration: none;
    color: #007BFF;
    font-weight: bold;
    transition: color 0.3s ease;
}

.specs a:hover {
    color: #0056b3;
    text-decoration: underline;
}

@media (max-width: 768px) {
    .proContainer {
        width: 95%;
        padding: 20px;
    }

    .closeBtn {
        top: 5px;
        right: 5px;
        padding: 5px 10px;
        font-size: 16px;
    }

    .proContainer p {
        font-size: 20px;
        margin-bottom: 15px;
    }
}
