.products {
    width: 100%;
}

.products .container {
    width: 1240px;
    margin: 4rem auto;
    padding: 4rem 0rem;
}

.products h2 {
    font-size: 3rem;
    text-align: center;
}

.products .container .content {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;
    margin: 4rem 0;
}

.products .card {
    border: 1px solid #eee;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgb(0,0,0,.2);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.products .card p{
    text-align: left;
    margin-bottom: 20px;
}

.products .card:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.products .card img {
    /* border-radius: 50px; */
    /* margin-top: -2rem; */
    /* margin-left: 6rem; */
    display: block;
    text-align: center;
    align-items: center;
    margin-bottom: 20px;
}


.products .card p {
    color: #787878;
    font-size: 1.1rem;
    margin: 12px;
}

.products .card span {
    color: var(--primary-color);
    font-size: 1.6rem;
    font-weight: 700;
}

@media screen and (max-width: 940px) {
    .products .container {
        width: 100%;
    }

    .products .container .content {
        grid-template-columns: 1fr;
    }

    .products .card {
        max-width: 100%;
        margin: 1rem;
    }

}

/* Mobile Styles */
/* @media screen and (max-width: 600px) {
    .products .container {
        width: 100%;
    }

    .products .container .content {
        grid-template-columns: 1fr;
    }

    .products .card {
        max-width: 100%;
        margin: 1rem;
    }
} */


  /* Tablet Styles */
  /* @media only screen and (min-width: 601px) and (max-width: 1260px) {
    .products .container {
        width: 100%;
    }

    .products .container .content {
        grid-template-columns: repeat(2,1fr);
        gap: 0px;
    }

    .products .card {
        max-width: 70%;
        margin: 1rem;
    }
  } */
  
  /* Desktop Styles */
  /* @media only screen and (min-width: 1261px) {
   
  } */
  



