.footer {
    width: 100%;
    background-color: #000;
    color: #eee;
    padding: 3rem 1rem;
}

.footer .container {
    max-width: 1240px;
    margin: auto;
    text-align: center;
}

.footer ul {
    display: flex;
    justify-content: center;
}

.footer li {
    padding: 1rem;
}

.footer li a {
    color: #fff;
}

.footer li a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
    cursor: pointer;
}

.bottom {
    text-align: center;
    padding-top: 3rem;
}

.line {
    margin-bottom: 1rem;
}


@media screen and (max-width:940px) {
    .footer {
        max-width: 100%;
        background-color: rgba(0,0,0,.9);
    }

    .footer .navbar {
        max-width: 100%;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
        background-color: rgba(0,0,0,.9);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }

}





