.contact {
    margin: 5rem auto;
    padding: 1rem;
    width: 100%;
}

.contact .container {
    width: 1240px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.contact .col-1 {
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.contact .col-1 .title {
    font-size: 2rem;
    font-style: italic;
    margin-top: 1rem;
}


.contact .col-1 .body {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}



.contact .col-2 {
    margin: auto;
}


@media screen and (max-width: 940px) {
    .contact .container {
        max-width: 100%;
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }

    .contact iframe {
        width: 100%;
        height: auto;
    }
}

